<script>
import EventBus from '@/event-bus'
import Repository from "@/app/repository/repository-factory";

const NotificationRepository = Repository.get("NotificationRepository");

/**
     * Mercure event component
     */
export default {
  data() {
    return {
      eventSource: null,
    };
  },
  created() {
    const mercureUrl = process.env.VUE_APP_MERCURE_URL;
    const url = new URL(mercureUrl);
    url.searchParams.append('topic', '*');

    const eventSource = new EventSource(url, {withCredentials: true});
    this.eventSource = eventSource;
    eventSource.onmessage = event => {
      let notify = JSON.parse(event.data);
      EventBus.$emit('notify', notify);

      if (notify.eventName !== null) {
        EventBus.$emit(notify.eventName, notify.eventPayload);
      }

      if (notify.showToast === true) {
        this.toast(notify.uuid, notify.title, notify.content, notify.type, notify.link, notify.priority, notify);
      }
    }
  },
  destroyed() {
    this.eventSource.close();
  },
  methods: {
    toast(uuid, title, content, type = 'success', link = null, priority = false, notifyObject) {
      let notify = null;
      let duration = 5000;

      if (priority === true) {
        duration = 0
      }

      notify = this.$notify({
        id: uuid,
        title: title,
        dangerouslyUseHTMLString: true,
        message: content,
        type: type,
        duration: duration,
        position: 'top-right',
        offset: 10,
        onClick: () => {

          if (notify.persistNotification === true) {
            NotificationRepository.markAsRead(notifyObject.uuid).then(() => {
              EventBus.$emit('notificationMarkAsRead', notifyObject);
            }).catch(error => {
              console.log(error);
            })
          }

          if (link !== null) {
            if (link !== this.$router.currentRoute.path) {
              this.$router.push({ path: link })
            }
          }

          notify.close();
        }
      });
    }
  },
};
</script>
<template>
    <div></div>
</template>

