<script>
import { authComputed } from "@/state/helpers";
import MercureEvent from "@/components/mercure-event";
import NotificationTopbar from "./notification/notification-topbar";

/**
 * Topbar component
 */
export default {
  components: { NotificationTopbar, MercureEvent },
  data() {
    return {
      loggedUser: null,
    };
  },
  created() {
    this.loggedUser = this.currentUser();
  },
  methods: {
    ...authComputed,
    toggleMenu() {
      this.$parent.toggleMenu();
    },
  }
};
</script>
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <MercureEvent />
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <b-link :to="{ name: 'home'}" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/si-pay-logo.png" alt height="15" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/si-pay-logo.png" alt height="30" />
            </span>
          </b-link>
          <b-link :to="{ name: 'home'}" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/si-pay-logo.png" alt height="15" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/si-pay-logo-light.png" alt height="30" />
            </span>
          </b-link>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item mt-2"
          id="vertical-menu-btn"
          @click="toggleMenu()"
        >
          <i class="mdi mdi-menu"></i>
        </button>


      </div>

      <div class="d-flex">

        <!-- <button type="button" class="btn header-item noti-icon" @click="decryptMailtoEmail('aXBwQGltcGVsLnBs')">
            <i class="mdi mdi-help-circle"></i>
        </button> -->

        <NotificationTopbar />

        <b-dropdown class="d-inline-block" right toggle-class="header-item" variant="white">
          <template v-slot:button-content>
          <div>
            <b-avatar v-if="loggedUser.profileImage !== null && loggedUser.profileImage !== ''" :src="loggedUser.profileImage" variant="secondary"></b-avatar>
            <b-avatar v-if="loggedUser.profileImage === null || loggedUser.profileImage === ''" variant="primary" :text="loggedUser.firstName.substring(0,1).toLocaleUpperCase() + loggedUser.lastName.substring(0,1).toLocaleUpperCase()"></b-avatar>
          </div>
          </template>
          <b-link class="dropdown-item" :to="{ name: 'Mój profil' }">
            <i class="mdi mdi-account-circle font-size-17 align-middle mr-1"></i> Profil
          </b-link>
          <b-link class="dropdown-item" :to="{ name: 'Mój profil' }" v-if="loggedUser.typeOfUserAccount === 1">
            <i class="mdi mdi-lock-open-outline font-size-17 align-middle mr-1"></i> Zmiana hasła
          </b-link>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="/logout">
            <i class="bx bx-power-off font-size-17 align-middle mr-1 text-danger"></i> Wyloguj
          </a>
        </b-dropdown>



      </div>
    </div>
  </header>
</template>

