<script>
import { authComputed } from "@/state/helpers";
import MercureEvent from "@/components/mercure-event";
import NotificationTopbar from "./notification/notification-topbar";

/**
 * Horizontal-topbar component
 */
export default {
  components: { NotificationTopbar, MercureEvent },
  data() {
    return {
      loggedUser: null,
    };
  },

  created() {
    this.loggedUser = this.currentUser();
  },
  methods: {
    ...authComputed,
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      let element = document.getElementById("topnav-menu-content");
      element.classList.toggle("show");
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    decryptMailtoEmail(encoded) {
      var address = atob(encoded);
      window.location.href = "mailto:" + address;
    }
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <MercureEvent />
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <b-link :to="{ name: 'home'}" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/si-pay-logo.png" alt height="30" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/si-pay-logo.png" alt height="30" />
            </span>
          </b-link>
          <b-link :to="{ name: 'home'}" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/si-pay-logo.png" alt height="30" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/si-pay-logo-light.png" alt height="30" />
            </span>
          </b-link>
        </div>

        <button type="button" class="btn btn-sm mr-2 mt-2 font-size-24 d-lg-none header-item" @click="toggleMenu">
          <i class="mdi mdi-menu"></i>
        </button>
      </div>

      <div class="d-flex">
        <!-- App Search
        <form class="app-search d-none d-lg-block">
            <div class="position-relative">
                <input type="text" class="form-control" placeholder="Search..."/>
                <span class="fa fa-search"></span>
            </div>
        </form>
        -->

        <b-dropdown v-if="1 === 0" class="d-none d-md-block ml-2" toggle-class="header-item" right variant="white">
          <template v-slot:button-content>
            <img class="mr-2" src="@/assets/images/flags/us_flag.jpg" alt="Header Language" height="16" /> English
            <span class="mdi mdi-chevron-down"></span>
          </template>
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img src="@/assets/images/flags/germany_flag.jpg" alt="user-image" class="mr-1" height="12" />
            <span class="align-middle">German</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img src="@/assets/images/flags/italy_flag.jpg" alt="user-image" class="mr-1" height="12" />
            <span class="align-middle">Italian</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img src="@/assets/images/flags/french_flag.jpg" alt="user-image" class="mr-1" height="12" />
            <span class="align-middle">French</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img src="@/assets/images/flags/spain_flag.jpg" alt="user-image" class="mr-1" height="12" />
            <span class="align-middle">Spanish</span>
          </a>

          <!-- item-->
          <a href="javascript:void(0);" class="dropdown-item notify-item">
            <img src="@/assets/images/flags/russia_flag.jpg" alt="user-image" class="mr-1" height="12" />
            <span class="align-middle">Russian</span>
          </a>
        </b-dropdown>

        <!-- <div class="dropdown d-none d-lg-inline-block">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
              <i class="mdi mdi-fullscreen"></i>
          </button>
        </div> -->

        <!-- <button type="button" class="btn header-item noti-icon" @click="decryptMailtoEmail('aXBwQGltcGVsLnBs')">
            <i class="mdi mdi-help-circle"></i>
        </button> -->

        <NotificationTopbar />

        <b-dropdown class="d-inline-block" right toggle-class="header-item" variant="white">
          <template v-slot:button-content>
          <div>
            <b-avatar v-if="loggedUser.profileImage !== null && loggedUser.profileImage !== ''" :src="loggedUser.profileImage" variant="secondary"></b-avatar>
            <b-avatar v-if="loggedUser.profileImage === null || loggedUser.profileImage === ''" variant="primary" :text="loggedUser.firstName.substring(0,1).toLocaleUpperCase() + loggedUser.lastName.substring(0,1).toLocaleUpperCase()"></b-avatar>
          </div>
          </template>
          <b-link class="dropdown-item" :to="{ name: 'Mój profil' }">
            <i class="mdi mdi-account-circle font-size-17 align-middle mr-1"></i> Profil
          </b-link>
          <b-link class="dropdown-item" :to="{ name: 'Mój profil' }" v-if="loggedUser.typeOfUserAccount === 1">
            <i class="mdi mdi-lock-open-outline font-size-17 align-middle mr-1"></i> Zmiana hasła
          </b-link>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="/logout">
            <i class="bx bx-power-off font-size-17 align-middle mr-1 text-danger"></i> Wyloguj
          </a>
        </b-dropdown>

        <div v-if="1 === 0" class="dropdown d-inline-block">
          <button type="button" class="btn header-item noti-icon right-bar-toggle toggle-right" @click="toggleRightSidebar">
            <i class="mdi mdi-settings-outline toggle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </header>
</template>
