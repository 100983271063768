<script>
import EventBus from "@/event-bus";
import { authComputed } from "@/state/helpers";
import Repository from "@/app/repository/repository-factory";

const NotificationRepository = Repository.get("NotificationRepository");

export default {
  name: "notification-topbar",

  data() {
    return {
      countNotificationAll: null,
      countNotificationUnread: null,
      userNotifications: {},
      todayDate: new Date().toISOString().slice(0, 10),
      isProcessing: false,
    };
  },
  created() {
    this.getAll(1, 10, true, "all");
    EventBus.$on("notificationChanged", () => {
      this.getAll(1, 10, true, "all");
    });
  },
  methods: {
    ...authComputed,
    markAsReadAll() {
      NotificationRepository.markAsReadAll()
        .then(() => {
          this.countNotificationUnread = 0;
          this.getAll(1, 10, true, "all");
          EventBus.$emit("notificationChanged");
          this.$notify({
            title: "Sukces!",
            dangerouslyUseHTMLString: false,
            message:
              "Wszystkie powiadomienia zostały oznaczone jako przeczytane",
            type: "success",
            duration: 2500,
            position: "top-right",
          });
        })
        .catch((error) => {
          this.$notify({
            title: "Błąd!",
            dangerouslyUseHTMLString: false,
            message:
              "Oznaczenie wszystkich powiadomieniń jako przeczytane nie powiodło się",
            type: "error",
            duration: 2500,
            position: "top-right",
          });
          console.log(error);
        });
    },
    getAll(page, maxItems, onlyUnread, module) {
      this.isProcessing = true;
      NotificationRepository.getNotifications(
        page,
        maxItems,
        onlyUnread,
        module
      )
        .then((response) => {
          this.countNotificationAll = response.data.info.countAllItems;
          this.countNotificationUnread = response.data.info.countUnreadItems;
          this.userNotifications = response.data.data.map((res) => ({
            ...res,
            isLoading: false,
          }));
          this.isProcessing = false;
        })
        .catch((error) => {
          this.isProcessing = false;
          console.log(error);
        });
    },
    clickToNotification(notification) {
      if (notification.link !== null) {
        window.location.href = notification.link;
      }
    },
    markAs(notification) {
      if (notification.markedAsRead === true) {
        this.markAsRead(notification);
      }

      if (notification.markedAsRead === false) {
        this.markAsUnread(notification);
      }
    },
    markAsRead(notification) {
      notification.isLoading = true;
      NotificationRepository.markAsRead(notification.notificationId)
        .then(() => {
          notification.isLoading = false;
          this.notifySuccess(notification);
          this.getAll(1, 10, true, "all");
          EventBus.$emit("notificationChanged");
        })
        .catch((error) => {
          notification.isLoading = false;
          console.log(error);
        });
    },
    markAsUnread(notification) {
      notification.isLoading = true;
      NotificationRepository.markAsUnread(notification.notificationId)
        .then(() => {
          notification.isLoading = false;
          this.notifySuccess(notification);
          this.getAll(1, 10, true, "all");
          EventBus.$emit("notificationChanged");
        })
        .catch((error) => {
          notification.isLoading = false;
          console.log(error);
        });
    },
    isFromToday(notification) {
      return (
        this.todayDate ===
        new Date(notification.datetime).toISOString().slice(0, 10)
      );
    },
    ntColor(type) {
      return type === "error" ? "danger" : "success";
    },
    notifySuccess(notification) {
      this.$notify({
        title: "Sukces!",
        dangerouslyUseHTMLString: true,
        message:
          "Status powiadomienia<br/>" +
          notification.notificationId +
          "<br/>został zmieniony",
        type: "success",
        duration: 2500,
        position: "top-right",
      });
    },
  },
  computed: {
    paramType() {
      return this.$route.params.type === "unread" ? true : false;
    },
  },
};
</script>

<template>
  <b-dropdown class="d-inline-block" toggle-class="header-item noti-icon" menu-class="dropdown-menu-lg dropdown-menu-notifications p-0" right variant="white">
    <template v-slot:button-content>
      <i class="mdi mdi-bell-outline"></i>
      <span style="font-size: 9px" v-if="countNotificationUnread > 0" class="badge badge-danger badge-pill mt-n1">{{ countNotificationUnread  > 9 ? '9+' : countNotificationUnread }}</span>
    </template>

    <div class="p-3 border-bottom">
      <div class="row">
        <div class="col d-flex justify-content-between">
          <h5 class="m-0 mt-1 font-size-16">
            <i class="mdi mdi-bell-outline mr-2" />Powiadomienia <span class="small text-muted">({{ countNotificationUnread }})</span>
          </h5>
          <button class="btn btn-sm btn-outline-warning" @click="markAsReadAll">
            <i class="mdi mdi-checkbox-marked-outline  mr-2" />Oznacz wszystkie jako przeczytane
          </button>
        </div>
      </div>
    </div>
    <div data-simplebar class="conversation-list px-0" v-loading="isProcessing">
      <div class="simplebar-scroll-content">
        <div class="simplebar-content">
          <template v-if="countNotificationUnread > 0">
            <div class="text-reset notification-item message-list border-bottom" v-for="(notification,index) in userNotifications" :key="index">
              <div class="media d-flex align-items-center notifyitem" v-loading="notification.isLoading" :class="isFromToday(notification) ? 'bg-new-message border-'+ ntColor(notification.type) : 'border-'+ ntColor(notification.type)">
                <b-form-checkbox class="mr-2" v-b-tooltip.hover :title="notification.markedAsRead ? 'Oznacz jako nieprzeczytane' : 'Oznacz jako przeczytane'" v-model="notification.markedAsRead" @change="markAs(notification)"></b-form-checkbox>
                <div class="media-body">
                  <small class="text-muted">
                    <i class="mdi mdi-calendar-clock mr-1"></i>
                    {{ notification.datetime }}
                  </small>
                  <h6 class="m-0 p-0">
                    <i class="mdi mdi-alert-decagram text-danger mr-1" v-if="notification.priority === true"></i>
                    {{ notification.title }}<el-tag size="mini" v-if="isFromToday(notification)">new</el-tag>
                  </h6>
                  <div class="font-size-12 text-muted">
                    <p class="p-0 m-0" v-html="notification.content"></p>
                  </div>
                </div>
                <div class="text-right ml-3" v-if="notification.link !== null">
                  <el-button size="mini" v-on:click="clickToNotification(notification)">Zobacz</el-button>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <p class="p-3 mb-0">Brak nowych powiadomień</p>
          </template>
        </div>
      </div>
    </div>
    <div class="p-3 border-top">
      <div class="row">
        <div class="col d-flex justify-content-between">
          <b-link class="btn btn-sm btn-outline-primary" :to="{ name: 'Moje powiadomienia', params: {type: 'all', module: 'all' } }">
            <i class="mdi mdi-format-list-numbered mr-2" /> Zobacz wszystkie
          </b-link>
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<style lang="scss" scoped>
.notifyitem {
  border-left: 5px solid;
}

.el-tag--mini {
  height: 14px;
  padding: 0 5px;
  line-height: 8px;
  margin-left: 5px;
  vertical-align: text-top;
}
</style>